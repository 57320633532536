<template>
     <!-- <video  class="hidden-mobile" playsinline autoplay muted loop id="myVideo">
  <source src="./movie6.mp4" type="video/mp4">
</video> -->

<!-- DESKTOP -->
  <div v-if="window" class="con">
  <div class="hidden-mobile">
  <p class="line-1 line-5 anim-typewriter">"Das Auge führt den Menschen in die Welt. Das Ohr führt die Welt in die Menschen." Lorenz Oken</p>
</div>
  </div>
  <div style="margin-bottom: 30px"  class="container" v-if="window">
  <!-- <div class="card " data-bs-theme="dark" >
    <iframe src="https://player.vimeo.com/video/936606431"  width="100%" height="620" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
    <div class="card-body">
      <h5>AKTION MENSCH! Janis McDavid </h5>
      <p class="card-text">MUSIC
        
      </p>
      
    </div>
</div> -->

<a @click="this.$root.navClick(2)" class="custom-dotted-link" href="#">See all projects →</a>
</div>
  <!-- MOBILE -->
  <!-- <div class=" con" v-if="!window">
  <div class="hidden-pc">
    <p class="line-2 line1 anim-typewriter">"Das Auge führt den </p>
    <p class="line-2 line2 anim-typewriter">Menschen in die Welt.</p>
    <p class="line-2 line3 anim-typewriter">Das Ohr führt die Welt</p>
    <p class="line-2 line4 anim-typewriter">in die Menschen." </p>
     <p class="line-2 line5 anim-typewriter">Lorenz Oken</p>
  </div>
 
  
</div> -->

<div class="container " v-if="!window">
  <div class="card " data-bs-theme="dark" >
    <iframe src="https://player.vimeo.com/video/936606431"  width="100%" height="200" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
    <div class="card-body">
      <h5>AKTION MENSCH! Janis McDavid </h5>
      <p class="card-text">MUSIC</p>

    </div>
</div>

<div class="card " data-bs-theme="dark" >
    <iframe src="https://player.vimeo.com/video/1020265461?h=632727d6de"  width="100%" height="200" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
    <div class="card-body">
      <h5>ERGO DFB MÄNNER</h5>
      <p class="card-text">SOUND DESIGN & FOLEY</p>
    </div>
  </div>
  <div class="card" data-bs-theme="dark" >
    <iframe src="https://player.vimeo.com/video/820561468?h=b693736773" width="100%" height="200" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
    <div class="card-body">
      <h5>IGEDO EXHIBITIONS IMAGETRAILER</h5>
      <p class="card-text">MUSIC & SOUND DESIGN </p>
    </div>
  </div>

  <div style="margin-bottom:2rem " class="container">
  <a style="text-align: left; position:relative" @click="this.$root.navClick(2)" class="custom-dotted-link" href="#">See all projects →</a>
</div>

</div>
<div   class="container" v-if="window">

  <P style="font-size: smaller; padding-bottom: 20px;">
    <!-- <p style="margin:0; padding:0;" >MUSIC PRODUCTION // SOUND DESIGN </p>  -->
    AKTION MENSCH! / STAATLICHE KUNSTSAMMLUNGEN DRESDEN / WILDERNESS INTERNATIONAL / BMW / ERGO / BACHAKADEMIE STUTTGART / DEUTSCHES HYGIENE-MUSEUM DRESDEN / KOST - KOOPERATION SCHULE UND THEATER IN SACHSEN / GRAFOX 
     / DVB - DRESDNER VERKEHRSBETRIEBE / MDR&nbsp;SACHSEN / TM&nbsp;CINEMATICS / PROPELLERFILM / CHRISTOPH BAUER / STÄDTISCHE BIBLIOTHEKEN DRESDEN / BA - BERUFSAKADEMIE SACHSEN / VERKEHRSMINISTERIUM / IGEDO EXHIBITIONS HECHTFILM </p>
  
</div>

<div class="container"  v-if="!window">
  <P style="font-size:x-small; text-align: left;">
   AKTION MENSCH! / STAATLICHE KUNSTSAMMLUNGEN DRESDEN / WILDERNESS INTERNATIONAL / BMW / ERGO / BACHAKADEMIE STUTTGART / DEUTSCHES HYGIENE-MUSEUM DRESDEN / KOST - KOOPERATION SCHULE UND THEATER IN SACHSEN / GRAFOX 
     / DVB - DRESDNER VERKEHRSBETRIEBE / MDR&nbsp;SACHSEN / TM&nbsp;CINEMATICS / PROPELLERFILM / CHRISTOPH BAUER / STÄDTISCHE BIBLIOTHEKEN DRESDEN / BA - BERUFSAKADEMIE SACHSEN / IGEDO EXHIBITIONS HECHTFILM </p>
  
</div>
<!-- <VueWriter :array="arr" class="line-1"/> -->
</template>

<script>




export default {
    name: 'HelloWorld',
    props: {
        msg: String
    },
    data() {
        return { arr: ["Hello", "From", "Vue Writer"],
        showProjects: false, };
    },
    computed: { window() {
            if (window.innerWidth > 990) {
                return true;
            }
            else {
                return false;
                

            }
        }
    },

    mounted() {
  
  },
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* #myVideo {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  background-color:black;
}
video {
   filter: brightness(70%);
} */

/* .div1{
  position: absolute;

  top: 40%;
  padding: 40px 20% 40px;
  text-align: center;
  margin: 0 auto;
} */


p{
  font-family: "Poppins";
  color: white;
  font-weight: bold;
}
.custom-dotted-link {
    text-decoration: underline;
    
    text-decoration-thickness: 2px; /* Dicke der Unterstreichung */
    text-underline-offset: 4px; /* Abstand der Unterstreichung vom Text */
    text-decoration-color: black; /* Farbe der Unterstreichung */
    /* Custom Abstand zwischen den Punkten */

    padding-bottom: 2px; /* Kann den Abstand zusätzlich anpassen */
    transition: all 0.3s ease;
}

.custom-dotted-link:hover{
  color:orange;
}


.line-1{
    position: relative;
    top: 30%;  
    bottom:80%;
    width: 150%;
    margin: 0 auto;
    border-right: 2px solid rgba(255,255,255,.75);
    font-size: 120%;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    transform: translateY(-50%);    
}

.line-2{
    position: relative;
    top: 50%;  
   
    margin: 0 auto;
    font-size: 120%;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    transform: translateY(-50%);    
}
.con{
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  
}

/* Animation */
.anim-typewriter{
  border-right: 2px solid rgba(17, 17, 17, 0);
  animation: typewriter 4s steps(44, end) 1s 1 normal both,
             blinkTextCursor 500ms steps(44,end) infinite;
}
@keyframes typewriter{
  from{width: 0%;}
  to{width: 100%;}
}
@keyframes blinkTextCursor{
  from{border-right-color:transparent; }
  to{border-right-color: rgba(255,255,255,.75);}
}

@keyframes blinkTextCursor2{
  from{border-right-color:transparent; }
  to{border-right-color: rgba(255,255,255,.75);}
}

/* .line1{
  border-right: 2px solid rgba(17, 17, 17, 0);
  animation: typewriter 1s steps(10,end) 0.5s 1 normal both,
             blinkTextCursor 500ms steps(25,end) 3;
}
.line2{
  border-right: 2px solid rgba(17, 17, 17, 0);
  animation: typewriter 1s steps(10,end) 0.5s 1 normal both,
             blinkTextCursor 500ms steps(25,end) 2;
             -webkit-animation-delay: 1.5s;
              animation-delay: 1.5s;
}
.line3{
  border-right: 2px solid rgba(17, 17, 17, 0);
  animation: typewriter 1s steps(10,end) 0.5s 1 normal both,
             blinkTextCursor 500ms steps(25,end) 2;
             -webkit-animation-delay: 2.5s;
              animation-delay: 2.5s;
}
.line4{
  border-right: 2px solid rgba(17, 17, 17, 0);
  animation: typewriter 1s steps(10,end) 0.5s 1 normal both,
             blinkTextCursor 500ms steps(25,end) 2;
             -webkit-animation-delay: 3.5s;
              animation-delay: 3.5s;
}
.line5{
  border-right: 2px solid rgba(17, 17, 17, 0);
  animation: typewriter 1s steps(10,end) 0.5s 1 normal both,
             blinkTextCursor 500ms steps(25,end) infinite;
             -webkit-animation-delay: 4.5s;
              animation-delay: 4.5s;
} */

.card{
  background-color:black;
  color: rgb(213, 213, 213);
  margin-bottom: 5%;
  
}

h5{
  font-size: medium;
  text-align: left;
}
.card-text{
  font-size: small;
  text-align: left;
}
</style>
