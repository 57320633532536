<template>
  <div class="con container">
    <div class="">
      <img class="rounded mobile2" alt="Me" src="../assets/ME4.png">
    <h5 >ABOUT ME</h5>
 
    <P >Since 2013 I have been enthusiastically producing electronic as well as acoustic music. Over the years I have dedicated my time to a wide range of projects. I have worked on the music and sound design for various films, have been involved in hip-hop production and am active as an artist myself, focusing on organic techno, electronica and downtempo.</p>
 
   <br>
 
   
  </div></div>
  
<br>

</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.con{
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  
}


img{
  shape-outside: circle();
	clip-path: circle();
	float: left;
  width: 250px;
  
  margin: 2em;
}
h5{
  color: white;

  
}
p{
  /* color: rgb(159, 159, 159); */
  color: white;
  font-family: Poppins;
  font-weight: bolder;
  text-align: left;
}
@media (max-width: 991px) {
        .mobile2 {
          
          float:none;
          display: block;
          margin-left: auto;
          margin-right: auto;
          width: 50%;
        }
      }
</style>
