<template>
  <div class="con">
    <div class="zentral">
    <h5>CONTACT</h5>
    <br>
    <P >Merten Schicht 
   <br> 10707 Berlin <br> Tel: +49 157 86842949 <br>merten[at]schicht.org<br>merten[at]paridescollective.de</p>
   <br>
   <br>
   <br>
   <br>
   <br>
   <p style="font-size: smaller;">Copyright 2024 Merten Schicht</p>
   <!-- <p style="font-size: smaller; font-style: italic;">100% self programmed</p> -->
   
  </div></div>
  
<br>

</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.con{
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  
}


h5{
  color: white;
  
}
p{
  /* color: rgb(159, 159, 159); */
  color: white;
  font-size: medium;
  font-family: Poppins;
  font-weight: bold;
  
}

</style>
