import { createApp } from 'vue'
import App from './App.vue'
import VueWriter from "vue-writer";
// import Vue from 'vue'
// import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'


// Vue.use(BootstrapVue)
// // Optionally install the BootstrapVue icon components plugin
// Vue.use(IconsPlugin)

createApp(App).use(VueWriter).mount('#app')

export default function (Vue, { head }) {
    // Add inline CSS
  
    
    // Add an external JavaScript before the closing </body> tag
    head.script.push({
      src: 'https://www.instagram.com/embed.js',
      
      async: true,
    })
  
 
  }
  
