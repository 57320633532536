<template>
  <header>
   <Transition>
   <nav class="navbar navbar-dark navbar-expand-xxl xxl-body-tertiary" > 
            <div class="container-fluid">
              <a @click="navClick(0)" class="navbar-brand hidden-mobile" id="title" href="#"><img src="./assets/Logo7.png" width="290">         </a>
              <a style="margin:0; padding:0;" @click="navClick(0)" class="navbar-brand hidden-pc" id="title"  href="#"><img src="./assets/Logo7.png" width="250">   </a>
            
            
              <button style="border:0;" class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="hidden-mobile" id="title" style="font-size:1.1em;" >Menu</span> 
                <span class="hidden-pc" ><span class="navbar-toggler-icon"></span></span> 
                <!-- class="navbar-toggler-icon" -->
              </button>
              <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                  <li class="nav-item  " style="text-align:right;">
                    <a data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" @click="navClick(2)" id="navi" :class="{'is-active': this.reference}" class="nav-link active"  aria-current="page" href="#">Projects</a>
                  </li>
                 
                  <li class="nav-item " style="text-align:right;">
                    <a  data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" @click="navClick(1)" id="navi" :class="{'is-active': this.projects}"  class="nav-link" href="#">References</a>
                   
                  </li>
                
                  <li class="nav-item  " style="text-align:right;">
                    <a data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" @click="navClick(3)" id="navi" :class="{'is-active': this.aboutme}" class="nav-link" href="#">About me</a>
                   
                  </li>
                

                  <li class="nav-item " style="text-align:right;">
                    <a  data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" @click="navClick(4)" id="navi" :class="{'is-active': this.contact}" class="nav-link" href="#">Contact</a>
                   
                  </li>
               

                
                </ul>
              </div>
              
            </div>
          </nav>
        </Transition>
        </header>

        <main style="margin-top: 10px">
      <Transition>
    <Home v-if="home"></Home>
  </Transition>

<Transition>
    <Reference v-if="reference"></Reference>
  </Transition>

  <Transition>
    <Projects v-if="projects"></Projects>
  </Transition>

  <Transition>
    <Aboutme v-if="aboutme"></Aboutme>
  </Transition>

  <Transition>
    <Contact v-if="contact"></Contact>
  </Transition>

</main>
    <!-- <footer> Copyright 2023 Merten Schicht</footer> -->
</template>

<script>
import Reference from './components/References2.vue'
import Projects from './components/Projects.vue'
import Home from './components/Home.vue'
import Aboutme from './components/Aboutme2.vue'
import Contact from './components/Contact.vue'

export default {
  name: 'App',
  components: {
    Reference,
    Projects,
    Home,
    Aboutme,
    Contact
  },
  methods:{
      navClick(x){

        // START HOME
        if(x==0){
          this.reference = false;
          this.projects = false;
          this.home = true;
          this.aboutme = false;
          this.contact = false;

        }
        // References
          if(x == 1){
            this.reference = true;
            this.projects = false;
            this.home = false;
            this.aboutme = false;
            this.contact = false;
          }

          // Projects
          if(x == 2){
            this.reference = false;
            this.projects = true;
            this.home = false;
            this.aboutme = false;
            this.contact = false;
          }

          // About me
          if(x == 3){
            this.reference = false;
            this.projects = false;
            this.home = false;
            this.aboutme = true;
            this.contact = false;
          }

          // Contact
          if(x == 4){
            this.reference = false;
            this.projects = false;
            this.home = false;
            this.aboutme = false;
            this.contact = true;
          }
          
          return;
      }
      
  },
  data: function() {
    return{
      reference: false,
      projects: false,
      home: true,
      aboutme: false,
      contact: false,

    }
  },
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100&display=swap');
.con{
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  
}


.navbar{
  /* background: #00000010; */
  
  /* padding-bottom: 40px; */
}


#navi{
  transition: 0.5s;
  font-size: 0.9em;
  color: white;

  /* font-family:'Poppins', sans-serif;
  font-weight: bolder; */
}

#navi:hover{
  color:rgb(255, 174, 112);
  
  
  
}

/* .is-active{
  color:rgb(255, 174, 112);
} */

body{
    background: black;
    color: white;
    font-family:'Poppins', sans-serif;
    

    }

/* TRANSITIONS */
.v-enter-active,
.v-leave-active {
  transition: opacity 0.4s ease;
}
.v-enter-from,
.v-leave-to {
  opacity: 0;
}

#title{
    color: white;
  
    /* font-weight: bold; */
    font-size:1.3em;
    }


/* a:hover{
  color: blue;
  font-family:'Poppins', sans-serif;
  font-weight: bold;
} */ 
a{
  color: white;

}


#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  padding-top: 10px;
}
footer{
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50px;
  color: rgb(175, 175, 175);
  font-size:0.3em;
  padding-top: 40px;
  padding-bottom: 40px;
  /* background-color: #000000; */
}

@media (max-width: 991px) {
        .hidden-mobile {
          display: none;
        }
        #navi{
  padding-right: 1em;
  
  /* font-family:'Poppins', sans-serif;
  font-weight: bolder; */
}
      }

@media (min-width: 992px) {
        .hidden-pc {
          display: none;
        }
        .card-columns{
          column-count: 4;
        }
        
      }
</style>
