<template>
  <div class="con container">
    <div class="zentral">
   
    <h5>REFERENCES</h5>
    
    <P >
      AKTION MENSCH! / STAATLICHE KUNSTSAMMLUNGEN DRESDEN / WILDERNESS INTERNATIONAL / BMW / ERGO / BACHAKADEMIE STUTTGART / DEUTSCHES HYGIENE-MUSEUM DRESDEN / KOST - KOOPERATION SCHULE UND THEATER IN SACHSEN / GRAFOX 
     / DVB - DRESDNER VERKEHRSBETRIEBE / MDR&nbsp;SACHSEN / TM&nbsp;CINEMATICS / PROPELLERFILM / CHRISTOPH BAUER / STÄDTISCHE BIBLIOTHEKEN DRESDEN / BA - BERUFSAKADEMIE SACHSEN / VERKEHRSMINISTERIUM / IGEDO EXHIBITIONS HECHTFILM </p>
  

  </div></div>
  
<br>

</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.con{
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  
}


h5{
  color: white;
  
}
p{
  /* color: rgb(159, 159, 159); */
  color: white;
  /* font-size: medium; */
  font-family: Poppins;
  font-weight: bold;
  
}

</style>
