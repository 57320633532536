<template>
  
 <!-- <iframe width="560" height="315" src="https://www.youtube.com/embed/d13HYtN-06E" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> -->
 <div class="container" data-bs-theme="dark" >
 <div  data-bs-theme="dark" >
  <div class="card " data-bs-theme="dark" >
    <iframe src="https://player.vimeo.com/video/936606431"  width="100%" :height="frameheight" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
    <div class="card-body">
      <h5>AKTION MENSCH! Janis McDavid </h5>
      <p class="card-text">MUSIC</p>
    </div>
  </div>

  <div class="card " data-bs-theme="dark" >
    <iframe src="https://player.vimeo.com/video/1020265461?h=632727d6de"  width="100%" :height="frameheight" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
    <div class="card-body">
      <h5>ERGO DFB MÄNNER</h5>
      <p class="card-text">SOUND DESIGN & FOLEY</p>
    </div>
  </div>

  <div class="card " data-bs-theme="dark" >
    <iframe src="https://player.vimeo.com/video/1020273814?h=39223c01b4"  width="100%" :height="frameheight" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
    <div class="card-body">
      <h5>ERGO DFB MÄNNER</h5>
      <p class="card-text">SOUND DESIGN & FOLEY</p>
    </div>
  </div>

  <div class="card" data-bs-theme="dark" >
    <iframe src="https://player.vimeo.com/video/820561468?h=b693736773" width="100%" :height="frameheight" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
    <div class="card-body">
      <h5>IGEDO EXHIBITIONS IMAGETRAILER</h5>
      <p class="card-text">MUSIC</p>
    </div>
  </div>

  <div class="card">
    <iframe class="card-img-top" height="250" src="https://www.youtube.com/embed/d13HYtN-06E" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    <div class="card-body">
      <h5>HOCHSCHULE FÜR KIRCHENMUSIK</h5>
      <p class="card-text">SOUND DESIGN & FOLEY</p>
    </div>
  </div>

  <div class="card">
    <iframe class="card-img-top" height="250" src="https://www.youtube.com/embed/zD__BV2jwss?si=X9NKj87WP-P-h_zo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    <div class="card-body">
      <h5>ÖKUMENISCHE SENIORENHILFE DRESDEN e.V. </h5>
      <p class="card-text">SOUND DESIGN, FOLEY & VOCAL</p>
    </div>
  </div>

  <div class="card">
    <iframe class="card-img-top" height="300" src="https://www.youtube.com/embed/5wmg1Zpkavo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    <div class="card-body">
      <H5>VISION.BACH</H5>
      <p class="card-text">SOUND DESIGN & FOLEY</p>
    </div>
  </div>

  <div class="card">
    <iframe class="card-img-top" width="640" height="400" src="https://www.youtube.com/embed/tu3c_gtExzk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    <div class="card-body">
      <h5>VIDEORUNDGANG "IM GEFÄNGNIS"</h5>
      <p class="card-text">INTRO JINGLE</p>
    </div>
  </div>

  <div class="card">
    <iframe class="card-img-top" height="300" src="https://www.youtube.com/embed/Hax8B6E3tY8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    <div class="card-body">
      <H5>NACHHALLT - MEDIENPROJEKT TU BERLIN</H5>
      <p class="card-text">MUSIC, SOUND DESIGN, FOLEY & VOCAL</p>
    </div>
  </div>

  <div class="card">
    <iframe src="https://player.vimeo.com/video/339395039?h=b2f4c0f9e2" width="100%" :height="frameheight" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
    <div class="card-body">
      <h5>5 Jahre KOST - Kooperation Schule und Theater in Sachsen</h5>
      <p class="card-text">MUSIC</p>
    </div>
  </div>



  <!-- <div class="card">
    <iframe class="card-img-top" height="250" src="https://www.youtube.com/embed/-fbt5DqHDsQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    <div class="card-body">
      <H5>NRW - WEITER DURCHSTARTEN</H5>
      <p class="card-text">SOUND DESIGN & FOLEY</p>
    </div>
  </div> -->


  <div class="card">
    <iframe style="border-radius:12px" src="https://open.spotify.com/embed/artist/6AgAUwug3IMyaejTUjnkhU?utm_source=generator&theme=0" width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
    <div class="card-body">
      <h5>MONTEZUMA</h5>
      <p class="card-text">MUSIC PRODUCTION</p>
    </div>
  </div>





  <div class="card">
    <a href="https://www.instagram.com/reel/CkX6jLGo1x5/?utm_source=ig_web_button_share_sheet" target="_blank"><img src="../assets/djungel1.png" height="auto" width="100%"></a>
    <div class="card-body">
      <h5>WILDERNESS INTERNATIONAL</h5>
      <p class="card-text">SOUND DESIGN & FOLEY</p>
    </div>
  </div>

  <div class="card">
    <a href="https://www.instagram.com/p/Ck8Av1rotNG/" target="_blank"><img src="../assets/djungel2.png" height="auto" width="100%"></a>
    <div class="card-body">
      <h5>WILDERNESS INTERNATIONAL</h5>
      <p class="card-text">SOUND DESIGN & FOLEY</p>
    </div>
  </div>

  <div class="card">
    <a href="https://www.instagram.com/p/CkspoagoTAE/" target="_blank"><img src="../assets/djungel3.png" height="auto" width="100%"></a>
    <div class="card-body">
      <h5>WILDERNESS INTERNATIONAL</h5>
      <p class="card-text">SOUND DESIGN & FOLEY</p>
    </div>
  </div>

  <div class="card">
    <a href="https://www.instagram.com/p/CkF2nv_o-SD/" target="_blank"><img src="../assets/djungel4.png" height="auto" width="100%"></a>
    <div class="card-body">
      <h5>WILDERNESS INTERNATIONAL</h5>
      <p class="card-text">SOUND DESIGN & FOLEY</p>
    </div>
  </div>
  


  <div class="card">
    <iframe class="card-img-top" height="250" src="https://www.youtube.com/embed/-_JmggDD6_A" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    <div class="card-body">
      <h5>AUSSTELLUNG "FUTURE FOOD"</h5>
      <p class="card-text">INTRO JINGLE</p>
    </div>
  </div>

  <div class="card">
    <iframe class="card-img-top" height="200" src="https://www.youtube.com/embed/1rAFFeQKYlw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    <div class="card-body">
      <h5>#WEITERLESENLIVE (SBD)</h5>
      <p class="card-text">INTRO JINGLE</p>
    </div>
  </div>



  <div class="card">
    <a href="https://www.instagram.com/p/CZY-YF-FR2b/" target="_blank"><img src="../assets/Saxonz.png" height="auto" width="100%"></a>
    <div class="card-body">
      <h5>ONE SHOT THE SAXONZ</h5>
      <p class="card-text">MUSIC & SOUND DESIGN & FOLEY</p>
    </div>
  </div>

  <div class="card">
    <a href="https://www.instagram.com/p/CX-3BM-lggi/" target="_blank"><img src="../assets/LOK.png" height="auto" width="100%"></a>
    <div class="card-body">
      <h5>TM CINEMATICS</h5>
      <p class="card-text">SOUND DESIGN & FOLEY</p>
    </div>
  </div>
<!-- update -->
  <div class="card">
    <a href="https://www.instagram.com/p/CXazE5GoSJP/" target="_blank"><img src="../assets/slack.png" height="auto" width="100%"></a>
    <div class="card-body">
      <h5>TM CINEMATICS</h5>
      <p class="card-text">SOUND DESIGN & FOLEY</p>
    </div>
  </div>

  <div class="card">
    <a href="https://www.instagram.com/p/CWSuXmIIXlO/" target="_blank"><img src="../assets/schneehund.png" height="auto" width="100%"></a>
    <div class="card-body">
      <h5>TM CINEMATICS</h5>
      <p class="card-text">SOUND DESIGN & FOLEY</p>
    </div>
  </div>

  <div class="card">
    <a href="https://www.instagram.com/p/CVurx5YI1Bn/" target="_blank"><img src="../assets/herbst.png" height="auto" width="100%"></a>
    <div class="card-body">
      <h5>TM CINEMATICS</h5>
      <p class="card-text">SOUND DESIGN & FOLEY</p>
    </div>
  </div>

  <div class="card">
    <iframe class="card-img-top" height="200" src="https://www.youtube.com/embed/1QPqiAujJtw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    <div class="card-body">
      <h5>MONTEZUMA - STØT</h5>
      <p class="card-text">MUSIC PRODUCTION</p>
    </div>
  </div>

  <div class="card">
    <iframe class="card-img-top" height="200" src="https://www.youtube.com/embed/Qsyk7aKT7ww" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    <div class="card-body">
      <h5>KLIMAVERTRÄGLICH REISEN - ABER WIE</h5>
      <p class="card-text">MUSIC</p>
    </div>
  </div>

  <div class="card">
    <iframe src="https://player.vimeo.com/video/498919516?h=2b6be095ac" width="100%" height="300" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
    <div class="card-body">
      <h5>EDGE</h5>
      <p class="card-text">MUSIC</p>
    </div>
  </div>
 </div>
</div>

</template>

<script>

    
export default {

  name: 'HelloWorld',
  props: {
    msg: String
  },
  data() {
  return {
    frameheight: 620
  }
},

methods: {
  
  onResize() {
    if (window.innerWidth > 960) {
      this.frameheight = 620
    } else {
      this.frameheight = 200
    }
  }
},

created() {
  this.onResize();  
  window.addEventListener('resize', this.onResize)
},



}


</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.card{
  background-color:black;
  color: rgb(213, 213, 213);
  margin-bottom: 2%;
  
}

h5{
  font-size: medium;
  text-align: left;
}

.card-text{
  font-size: small;
  text-align: left;
}
p{
  font-family: "Poppins";
  color: white;
  font-weight: bold;
}


</style>
